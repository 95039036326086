/* eslint-disable */
import React from "react"
import { graphql, Link } from "gatsby"
//import Layout from "../layouts";
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <React.Fragment>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div dangerouslySetInnerHTML={{ __html: data.chevrolet.page.content }} />


    {/* <div
      id="post-area"
      className="uk-container uk-container-expand uk-padding-large"
    > */}
      {/* <div
        className="uk-child-width-1-2@s uk-child-width-1-3@m"
        data-uk-grid="true"
      >
        {data.chevrolet.posts.edges.map(row => (
          <div key={row.node.id} className="uk-grid-match">
            <div className="uk-card post-item  uk-paddin-remove ">
              <Link to={row.node.slug}>
                <div
                  className=" uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-margin-bottom"
                  data-src={row.node.featuredImage.sourceUrl}
                  data-uk-img="true"
                />
                <p className="item-title">{row.node.title}</p>
              </Link>
              <hr />
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: row.node.excerpt }} />
                <Link to={row.node.slug}> Leer más </Link>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    {/* </div> */}
  </React.Fragment>
)

export default IndexPage
export const query = graphql`
  query {
    chevrolet {
      page(id: "cGFnZTo1ODY=") {
        slug
        content
      }
      posts(last: 3, where: { status: PUBLISH }) {
        edges {
          node {
            id
            slug
            title
            excerpt
            featuredImage {
              id
              sourceUrl
            }
          }
        }
      }
    }
  }
`
